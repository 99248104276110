import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'folder/Inbox',
    pathMatch: 'full',
  },
  {
    path: 'folder/:id',
    loadChildren: () =>
      import('./folder/folder.module').then((m) => m.FolderPageModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'forgot',
    loadChildren: () =>
      import('./forgot/forgot.module').then((m) => m.ForgotPageModule),
  },
  {
    path: 'change-password',
    loadChildren: () =>
      import('./change-password/change-password.module').then(
        (m) => m.ChangePasswordPageModule
      ),
  },
  {
    path: 'view-accounts',
    loadChildren: () =>
      import('./view-accounts/view-accounts.module').then(
        (m) => m.ViewAccountsPageModule
      ),
  },
  {
    path: 'account-details',
    loadChildren: () =>
      import('./account-details/account-details.module').then(
        (m) => m.AccountDetailsPageModule
      ),
  },
  {
    path: 'lookup-procedure-codes',
    loadChildren: () =>
      import('./lookup-procedure-codes/lookup-procedure-codes.module').then(
        (m) => m.LookupProcedureCodesPageModule
      ),
  },
  {
    path: 'lookup-icd-codes',
    loadChildren: () =>
      import('./lookup-icd-codes/lookup-icd-codes.module').then(
        (m) => m.LookupIcdCodesPageModule
      ),
  },
  {
    path: 'lookup-procedure-codes-page',
    loadChildren: () =>
      import(
        './lookup-procedure-codes-page/lookup-procedure-codes-page.module'
      ).then((m) => m.LookupProcedureCodesPagePageModule),
  },
  {
    path: 'lookup-icd-codes-page',
    loadChildren: () =>
      import('./lookup-icd-codes-page/lookup-icd-codes-page.module').then(
        (m) => m.LookupIcdCodesPagePageModule
      ),
  },
  {
    path: 'capture-claim',
    loadChildren: () =>
      import('./capture-claim/capture-claim.module').then(
        (m) => m.CaptureClaimPageModule
      ),
  },
  {
    path: 'signin',
    loadChildren: () =>
      import('./signin/signin.module').then((m) => m.SigninPageModule),
  },  {
    path: 'anaesthetist',
    loadChildren: () => import('./anaesthetist/anaesthetist.module').then( m => m.AnaesthetistPageModule)
  },
  {
    path: 'view-anaesthetists',
    loadChildren: () => import('./view-anaesthetists/view-anaesthetists.module').then( m => m.ViewAnaesthetistsPageModule)
  },
  {
    path: 'anaesthetist-details',
    loadChildren: () => import('./anaesthetist-details/anaesthetist-details.module').then( m => m.AnaesthetistDetailsPageModule)
  },
  {
    path: 'view-users',
    loadChildren: () => import('./view-users/view-users.module').then( m => m.ViewUsersPageModule)
  },
  {
    path: 'users-details',
    loadChildren: () => import('./users-details/users-details.module').then( m => m.UsersDetailsPageModule)
  },
  {
    path: 'list-codes',
    loadChildren: () => import('./list-codes/list-codes.module').then( m => m.ListCodesPageModule)
  },
  {
    path: 'view-code',
    loadChildren: () => import('./view-code/view-code.module').then( m => m.ViewCodePageModule)
  },
  {
    path: 'create-quote',
    loadChildren: () => import('./create-quote/create-quote.module').then( m => m.CreateQuotePageModule)
  },
  {
    path: 'view-quotes',
    loadChildren: () => import('./view-quotes/view-quotes.module').then( m => m.ViewQuotesPageModule)
  },
  {
    path: 'lookup-price-page',
    loadChildren: () => import('./lookup-price-page/lookup-price-page.module').then( m => m.LookupPricePagePageModule)
  },
  {
    path: 'view-price-page',
    loadChildren: () => import('./view-price-page/view-price-page.module').then( m => m.ViewPricePagePageModule)
  },
  {
    path: 'lookup-modifier-codes',
    loadChildren: () => import('./lookup-modifier-codes/lookup-modifier-codes.module').then( m => m.LookupModifierCodesPageModule)
  },
  {
    path: 'profile-details',
    loadChildren: () => import('./profile-details/profile-details.module').then( m => m.ProfileDetailsPageModule)
  },
  {
    path: 'daily-deport',
    loadChildren: () => import('./daily-deport/daily-deport.module').then( m => m.DailyDeportPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
